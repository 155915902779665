import React, { useEffect, useState } from 'react';
import { Button, Card, Snackbar, Typography, Stack } from '@mui/joy';

const UpdateNotification=()=> {
  const [waitingWorker, setWaitingWorker] = useState(null);
  const [showRefresh, setShowRefresh] = useState(false);

  const handleClose = () => {
    setShowRefresh(false)
  }

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      // Register the service worker
      //console.log({navigator})
      console.log('sw in navigator')
      navigator.serviceWorker.register('../../../service-worker.js').then(registration => {
        if (!navigator.serviceWorker.controller) {
            console.log('no sw in navigator')
          return;
        }
        
        registration.update();
        
        onNewServiceWorker(registration, () => {
          setWaitingWorker(registration.waiting);
          console.log('should show refresh now')
          setShowRefresh(true);
        });

        let refreshing = false;
        navigator.serviceWorker.addEventListener('controllerchange', () => {
          if (!refreshing) {
            refreshing = true;
            console.log('Reloading page');
            window.location.reload();
          }
        });
      });
    }
  }, []);

  const onNewServiceWorker = (registration, callback) => {
    console.log('about to check if sw waiting')
    if (registration.waiting) {
        console.log('sw is waiting')
      return callback();
    } else {
        console.log('sw not waiting')
    }

    const listenInstalledStateChange = () => {
      registration.installing.addEventListener('statechange', (event) => {
        if (event.target.state === 'installed') {
            console.log('sw state installed')
          callback();
        }
      });
    };

    if (registration.installing) {
        console.log('registration installing')
      listenInstalledStateChange();
    }

    registration.addEventListener('updatefound', listenInstalledStateChange);
  };

  const handleRefreshClick = () => {
    if (waitingWorker) {
        console.log('posting skipwaiting')
      waitingWorker.postMessage('skipWaiting');
    }
  };

  return (
    <>
      {showRefresh && (
        <Snackbar
        open={showRefresh}
        onClose={handleClose}
        variant='soft'
        anchorOrigin={{vertical:'bottom',horizontal:'center'}}
        sx={{mb:'20px',
            mr:'20px',
            borderRadius: '5px',
            borderWidth:'2px'
        }}
        >
            <Stack direction={'row'} alignItems='center' spacing={1}>
<Typography fontFamily="Bai Jamjuree" >Update Available. Please update</Typography>
        
          <Button fontFamily="Bai Jamjuree"  variant='plain' color='success' borderRadius='50px' onClick={handleRefreshClick}>
            UPDATE
          </Button>
            </Stack>
        
        </Snackbar>
      )}
    </>
  );
}



export default UpdateNotification;
