import React,{ useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { usePosition } from '../utils/MapUtils/MapContext/PositionContext';

import { Box } from '@mui/joy';

import { WaiverModalIMPORTANT, SpearheadWarningModal } from '../utils/Modals/infoUpdateModals';
import {BlankMapBody, BlackcombMapBody, SpearheadMapBody, WhistlerMapBody,WB512300, WB512450, WB1024300, WB1024450,} from './mapBody';
import NavBar from './Navbar'
import Footer from '../utils/Footer';

const UnAuthMaps =()=>{

    const [currentMap, setCurrentMap] = useState('wb512300')
    const [signWaiver, setSignWaiver]= useState(true)
    const [spearheadWarning, setSpearheadWarning] = useState(false)
    
    const { setPosition } = usePosition();
    // navigation calls
    const location = useLocation();
    const navigate = useNavigate()
  
// handles spearhead disclaimer logic
    const handleSpearheadWarningClose=()=> {
        setSpearheadWarning(false)
      }
      useEffect(()=>{
        if (currentMap === 'spearhead') {
          setSpearheadWarning(true)
        }
      }, [currentMap])

// waiver signing
    const handleSignWaiverClose = () => {
    setSignWaiver(false)
    }

// handles changing of current map
    const handleSetCurrentMap = (map) => {
    setCurrentMap(map)
    setPosition({ latitude: null, longitude: null, zoom: null })
    }

    return (


        <Box className={location.pathname === '/login' ? 'login-background' : ''}>

        <NavBar handleSetCurrentMap={handleSetCurrentMap} currentMap={currentMap} setCurrentMap={setCurrentMap}/>
        <WaiverModalIMPORTANT open={signWaiver} handleClose={handleSignWaiverClose} signedIn={true}/>
        <SpearheadWarningModal open={spearheadWarning} handleClose={handleSpearheadWarningClose}/>
        {location.pathname === '/maps'
        &&
        currentMap === 'blankmap' ? <BlankMapBody handleSetCurrentMap={handleSetCurrentMap}/>:
        currentMap === 'spearhead' ? <SpearheadMapBody isSignedIn={false}/>:
        currentMap === 'wb512300' ? <WB512300 isSignedIn={false}/>:
        currentMap === 'wb512450' ? <WB512450 isSignedIn={false}/>:
        currentMap === 'wb1024300' ? <WB1024300 isSignedIn={false}/>:
        currentMap === 'wb1024450' ? <WB1024450 isSignedIn={false}/>:
        <BlankMapBody handleSetCurrentMap={handleSetCurrentMap}/>
        }
        <Footer/>
        </Box>

    )
}
export {UnAuthMaps}