import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { usePosition } from '../../utils/MapUtils/MapContext/PositionContext';

import { SupportForm } from '../../utils/Modals/SupportToggle';

import Box from '@mui/joy/Box';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import Button from '@mui/joy/Button';


export default function ContactPage({handleSetCurrentMap}) { 
    const { userID} = usePosition();
    const [result, setResult ] = useState("");
    const navigate = useNavigate()

    const onSupportSubmit = async (event) => {
        event.preventDefault();
        setResult("Sending....");
        const formData = new FormData(event.target);
    
        formData.append("access_key", "bcaf3925-5598-40a8-afeb-8a59aee7c7c0");
    
        const response = await fetch("https://api.web3forms.com/submit", {
          method: "POST",
          body: formData
        });
    
        const data = await response.json();
    
        if (data.success) {
          setResult("Form Submitted Successfully!");
        } else {
          console.log("Error", data);
          setResult(data.message);
        }
      };

// return to home
    const handleReturn = () => {
        handleSetCurrentMap('wb512300')
    }


    return (
        <Box width='100%' height='calc(100dvh - 60px)' sx={{overflowY:'scroll'}}>
            <Stack width='100%' height='100%' justifyContent='space-around' alignItems='center' marginBottom={2}>
                <Typography level='h3'>Get in touch with us</Typography>
                <SupportForm userID={userID} result={result} onSubmit={onSupportSubmit}/>
                <Button color='neutral' onClick={handleReturn}>Return to main page</Button>
            </Stack>
        </Box>
    )
}