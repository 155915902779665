import React from 'react';
import { useNavigate } from 'react-router-dom';

import { usePosition } from '../../utils/MapUtils/MapContext/PositionContext';

import Box from '@mui/joy/Box';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import Button from '@mui/joy/Button';


export default function PrintPurchasePage({handleSetCurrentMap}) { 
    const {currentUserRoles} = usePosition();
    const navigate = useNavigate()

// return to home
    const handleReturn = () => {
        handleSetCurrentMap('wb512300')
    }


    return (
        <Box width='100%' height='calc(100dvh - 60px)'>
            <Stack width='100%' height='100%' justifyContent='center' alignItems='center'>
                <Typography>This is PrintsPurcahse Page</Typography>
                <Typography>tbd</Typography>
                <Button color='neutral' onClick={handleReturn}>Return to main page</Button>
            </Stack>
        </Box>
    )
}