import React, { useState } from "react";
import { Modal, Box, Button, Typography, Stack, ModalClose, Link, Divider, Select, Option, Input, FormLabel, Textarea } from "@mui/joy";
import { isMobile, isBrowser, isIOS } from "react-device-detect";
import { usePosition } from "../MapUtils/MapContext/PositionContext";

import { BasicAssets } from "../../assets/basicAssets";
import alex from '../../assets/aboutImgs/alex.png'
import v1 from '../../assets/aboutImgs/v1.png'
import v2 from '../../assets/aboutImgs/v2.png'
import v3 from '../../assets/aboutImgs/v3.png'

const BeerButton = () => {
return(

  <Button
  variant="soft"
  color="neutral"
  size="md"
  sx={{ width: '100%', mt: 2 }}
  onClick={() => window.open('https://buymeacoffee.com/ullrmaps', '_blank')}
  >
    Buy Us a Beer 🍺
  </Button>
)
}

const AboutInfo = () => {

  return (
      <Box
      sx={{
        padding: 2,
        marginTop:isIOS? '50px':'',
        maxWidth: "800px",
        //margin: "0 auto",
        borderRadius: 8,
        backgroundColor: "white",
        overflowY:'auto'
      }}
    >

<Typography fontSize={18} fontWeight='bold' sx={{ mb: 1 }}>
        What is ULLR?
      </Typography>
      <Typography sx={{ mb: 2 }}>
        <strong>Ullr</strong> (sometimes spelled Ull or Ullur) is a figure from Norse mythology, often associated
        with skiing, hunting, and winter. He is considered a god or demi-god, depending on the
        mythological source, and is known for his exceptional skill in archery and skiing.
        <br />
        <br />
        In modern times, Ullr has become a cultural icon for winter sports communities. Many ski
        towns celebrate him with festivals, rituals, or events, hoping to ensure good snowfall during
        the season.
        <br />
        <br />
        For ULLR Maps, the name seems particularly fitting, as it reflects the focus on skiing, snow
        navigation, and the winter outdoors.
      </Typography>

<img src={alex} alt='alex'  style={{width:isMobile?'90vw':'400px'}}/>
      <Typography sx={{ mb: 2 }}>
        <strong>Alex Hordal</strong> (B.A. Geography) is the founder, cartographer, and driving force behind ULLR
        Maps, a cutting-edge, field-proven mapping platform dedicated to creating the ultimate digital
        ski maps and promoting the safe exploration of Whistler Blackcomb and surrounding terrain.
        With a background as a professional Whistler Blackcomb ski patroller and avalanche
        technician, Alex brings firsthand expertise in mountain safety and navigation to every map he
        creates. His time in the mountains instilled a deep appreciation for the challenges outdoor
        enthusiasts face in exploring both resort and backcountry terrain, inspiring him to develop
        tools that empower adventurers to explore with confidence.
        <br />
        <br />
        Formerly operating as the Whistler Mountain Mapping Initiative (2013), Alex’s vision evolved
        into ULLR Maps in 2017 with the launch of a dedicated iOS and Android app that merged
        advanced digital mapping technology with a passion for skiing, and subsequently won the
        Innovative Business of the Year award in 2018.
      </Typography>

      <Typography sx={{ mb: 2 }}>
        In late 2024, ULLR Maps relaunched as a community-driven, free-to-use progressive web
        application, available on mobile, PC, and tablet, with an emphasis on accuracy, completeness,
        and aesthetic beauty.
      </Typography>

      <Typography mb={1}><strong>Our Maps Over Time</strong></Typography>
      <Stack spacing={-.5} mb={1}>
          <img src={v1} alt='map' style={{width:isMobile?'90vw':'400px'}}/>
          <Typography>Version 1.0 - Whistler Mountain Ski Map.</Typography>
          </Stack>
          <Stack spacing={-.5} mb={1}>
          <img src={v2} alt='map' style={{width:isMobile?'90vw':'400px'}}/>
          <Typography>Version 2.0 – Whistler Mountain Ski Map.</Typography>
          </Stack>
          <Stack spacing={-.5} mb={1}>
          <img src={v3} alt='map' style={{width:isMobile?'90vw':'400px'}}/>
          <Typography>Whistler and Blackcomb Mountain Ski Map – 2024.</Typography>
</Stack>


<Typography fontSize={18} fontWeight='bold' sx={{ mb: 1 }}>
        ULLR In the Media
      </Typography>
      <Typography sx={{ mb: 2 }}>
        <Link href="https://geospatialworld.net/news/ullr-maps-launches-new-app-detailed-digital-maps-real-time-gps-tracking/" target="_blank">
          Geospatial World: “ULLR Maps launches new app with detailed digital maps and real-time GPS tracking” (Nov 27, 2017)
        </Link>
        </Typography>
        <Typography sx={{ mb: 2 }}>
        <Link href="https://www.whistler.com/blog/post/2020/12/24/apps-for-skiing/" target="_blank">
          Whistler Insider: “Tech Leap: Six Apps for Your Day on the Slopes”
        </Link>
        </Typography>
        <Typography sx={{ mb: 2 }}>
        <Link href="https://www.whistlerchamber.com/ullr-maps-offers-digital-direction-for-on-mountain-adventures/" target="_blank">
          Whistler Chamber of Commerce – “Ullr Maps offers digital direction for on-mountain adventures” (January 16, 2018)
        </Link>
        </Typography>
        <Typography sx={{ mb: 2 }}>
        <Link href="https://www.youtube.com/watch?v=1sTJb0P4xMc" target="_blank">
          Product Launch Video (2018)
        </Link>
        </Typography>
  

        <Typography fontSize={18} fontWeight='bold' sx={{ mb: 1 }}>
        Awards
      </Typography>
      <Typography>
        <Link href="https://www.facebook.com/WhistlerChamber/videos/2059933470766151/" target="_blank">
          Innovative Business of the Year 2018
        </Link>
      </Typography>

      <BeerButton/>
    </Box>
  )
}

const SupportULLRInfo = () => {

  return (
      <Box
      sx={{
        padding: 2,
        maxWidth: "800px",
        marginTop:isIOS? '50px':'',
        borderRadius: 8,
        backgroundColor: "white",
        overflowY:'auto'
      }}
    >

<Typography fontSize={18} fontWeight='bold' sx={{ mb: 1 }}>
        Greetings, winter adventurers!
      </Typography>

      <Typography sx={{ mb: 3 }}>
        We’re stoked to have you exploring the stunning terrain of Whistler Blackcomb and the surrounding backcountry zones with our app. 
        Whether you’re navigating the resort or venturing into the great unknown, our goal is to make your experience safer, 
        more informed, and endlessly enjoyable.
      </Typography>

      {/* Support Section */}
      <Typography fontSize={18} fontWeight='bold' sx={{ mb: 1 }}>
        Why We Need Your Support
      </Typography>

      <Typography sx={{ mb: 3 }}>
        While the app is free for everyone to use, keeping it running smoothly and adding exciting new features takes time, effort, and funding. 
        From maintaining server costs to supporting development, every bit of help ensures we can continue improving and expanding the tools 
        that help you make the most of your mountain days.
      </Typography>


      {/* Features Section */}
      <Typography fontSize={18} fontWeight='bold' sx={{ mb: 1 }}>
        New Features in the Works
      </Typography>

      <Stack spacing={2} sx={{ mb: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Box sx={{ width: 10, height: 10, backgroundColor: 'orange', borderRadius: '50%' }} />
          <Typography width={'97%'}  >Offline Mode: Access maps like the iconic Spearhead Traverse when cell service wanes. </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Box sx={{ width: 10, height: 10, backgroundColor: 'orange', borderRadius: '50%' }} />
          <Typography width={'97%'}  >Additional Maps: We’re expanding the areas covered in the app—stay tuned for surprises!</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Box sx={{ width: 10, height: 10, backgroundColor: 'red', borderRadius: '50%' }} />
          <Typography width={'97%'}  >
            Social Features: Share pins, photos, your location, and beta with other users. Whether it’s current snow conditions or tips for a zone, real-time info can be a game changer. More info to come.
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Box sx={{ width: 10, height: 10, backgroundColor: 'red', borderRadius: '50%' }} />
          <Typography width={'97%'}  >
            Additional Guidebook features: Images included with the database, a checklist to allow for tracking of objectives.
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Box sx={{ width: 10, height: 10, backgroundColor: 'red', borderRadius: '50%' }} />
          <Typography width={'97%'}  >
            Avalanche Information: Inclusion of Avalanche prone Areas as a shader layer over any backcountry areas
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Box sx={{ width: 10, height: 10, backgroundColor: 'red', borderRadius: '50%' }} />
          <Typography width={'97%'}  >
            Weather Data: Integration of Live Weather Data based on the current map view.
          </Typography>
        </Box>
      </Stack>

      {/* Legend Section */}
      <Typography level="h5"  fontWeight='bold' sx={{ mb: 0 }}>
        Legend:
      </Typography>
      <Stack spacing={0} sx={{ mb: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Box sx={{ width: 10, height: 10, backgroundColor: 'red', borderRadius: '50%' }} />
          <Typography level="body2">Concept Stage</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Box sx={{ width: 10, height: 10, backgroundColor: 'orange', borderRadius: '50%' }} />
          <Typography level="body2">In Development</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Box sx={{ width: 10, height: 10, backgroundColor: 'green', borderRadius: '50%' }} />
          <Typography level="body2">Complete</Typography>
        </Box>
      </Stack>

      <Typography   sx={{ mb: 2 }}>
        If you’ve enjoyed using the app, we’d love your support! Think of it as buying us a beer—a small way to say thanks and help us keep the app thriving. And, of course, we are always open to suggestions of new features you would like to see! Cheers and happy adventuring!
      </Typography>

      {/* Support Button */}
      <BeerButton/>

      
    </Box>
  )
}

const GetInTouchInfo = ({handleClose}) => {

  const { userID} = usePosition();
  const [result, setResult ] = useState("");

  const onSupportSubmit = async (event) => {
      event.preventDefault();
      setResult("Sending....");
      const formData = new FormData(event.target);
  
      formData.append("access_key", "bcaf3925-5598-40a8-afeb-8a59aee7c7c0");
  
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        body: formData
      });
  
      const data = await response.json();
  
      if (data.success) {
        setResult("Form Submitted Successfully!");
      } else {
        console.log("Error", data);
        setResult(data.message);
      }
    };

// return to home
  const handleReturn = () => {
    handleClose()
  }
  return (
    <Box
    sx={{
      padding: 2,
      maxWidth: "800px",
      marginTop:isIOS? '50px':'',
      width:isMobile?'90vw':'425px',
      borderRadius: 8,
      backgroundColor: "white",
      overflowY:'auto',
    }}
  >
      <form onSubmit={onSupportSubmit}>
        <Stack spacing={1}>
          <Stack spacing={0.5}>
            <FormLabel>Name</FormLabel>
            <Input type="text" name="name" required defaultValue={userID ? userID.username : ''} />
          </Stack>

          <Stack spacing={0.5}>
            <FormLabel>Email</FormLabel>
            <Input type="email" name="email" required defaultValue={userID ? userID.email : ''} />
          </Stack>

          <Stack spacing={0.5} sx={{ maxWidth: '100%', overflow: 'hidden' }}>
            <FormLabel>Subject</FormLabel>
            <Select slotProps={{ listbox: { sx: { width: '100%' }}}} name="subject" required placeholder="Choose an option...">
              <Option value="Website Issue">Website Issue</Option>
              <Option value="Suggest a Correction">Suggest a Correction</Option>
              <Option value="Suggest an Addition">Suggest an Addition</Option>
              <Option value="Feature Request">Feature Request</Option>
              <Option value="General Inquiry">General Inquiry</Option>
            </Select>
          </Stack>

          <Stack spacing={0.5}>
            <FormLabel>Message</FormLabel>
            <Textarea name="message" required minRows={isMobile?10:6} maxRows={isMobile?14:9} />
          </Stack>

          <Button variant='outlined' color="neutral" type="submit">Submit Form</Button>
        </Stack>
      </form>
      <Typography color='neutral'  mt={2} level='h4'>{result}</Typography>
  </Box>
  )
}

const ModalPageTemplate = ({open, handleClose, returnToMainNav, DataInput, title}) => {

  return (
    <>
    {isMobile &&
      <Modal
        open={open}
        onClose={handleClose}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
      >
        <Box
          sx={{
            width: "100vw",
            height: "100vh",
            backgroundColor: "white",
            p: 0,
            boxShadow: "lg",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            //justifyContent: "center",
            
          }}
        >
<Stack alignContent={'left'} alignItems={'center'} 
    sx={{ 
        position: 'sticky', 
        top: 0, 
        zIndex: 1, 
        backgroundColor: 'white', // To avoid overlapping background issues
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        }}>
    <Box py={0.5} paddingLeft={1} width={'100vw'} height={'40px'}>
        <Stack direction='row' spacing={2} alignItems='center' justifyContent={'flex-start'}>
        <img className='smallReturn' src={BasicAssets.leftArrow} alt='return' onClick={returnToMainNav}/>
            <Typography level='h4' fontWeight='bold'>{title}</Typography>
        </Stack>
        
    </Box>
    <ModalClose size='sm' onClick={handleClose}  />
        <Divider />
    </Stack>
      <DataInput handleClose={handleClose}/>
        </Box>
      </Modal>
}
{isBrowser &&
<>
<Box
  sx={{
    position: 'fixed', // Fix it to the screen
    top:'70px',
    left: 0,           // Align to the left of the screen
    width: '450px',    // Set the width to 200px
    height: 'calc(100vh - 110px)',
    //minHeight: activeIcon.newPin?'300px':'600px',   // Make the height fill the entire screen
    //maxHeight:activeIcon.newPin?'300px':'600px',
    zIndex: 1008,      // Ensure it's on top of other elements
    backgroundColor: 'white', // Optional: add a background color
    boxShadow: '2px 0 5px rgba(0, 0, 0, 0.1)', // Optional: slight shadow for better visibility
    overflowY: 'auto',
    marginLeft:'50px',
    borderRadius:'10px',
    border: '1px solid grey',
    
  }}
  open={open}
  onClose={handleClose}
  anchor="left"
>
    <Stack alignContent={'left'} 
    sx={{ 
        position: 'sticky', 
        top: 0, 
        zIndex: 1, 
        backgroundColor: 'white', // To avoid overlapping background issues
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        }}>
    <Box py={isMobile?0.5:1} marginLeft={'8px'}>
        <Stack direction='row' spacing={2} alignItems='center'>
        <img className='smallReturn' src={BasicAssets.leftArrow} alt='return' onClick={returnToMainNav}/>
            <Typography level='h4' fontWeight='bold'>{title}</Typography>
        </Stack>
        
    </Box>
    <ModalClose size='sm' onClick={handleClose}  />
        <Divider />
    </Stack>
    <DataInput/>
            </Box>
</>
}
    </>
  );
};

const AboutULLRPageModal = ({ open, handleClose, returnToMainNav,}) => {
  const title='About ULLR Maps'
  return (
<ModalPageTemplate DataInput={AboutInfo} open={open} handleClose={handleClose} returnToMainNav={returnToMainNav} title={title}/>
  )
}

const SupportULLRPageModal = ({ open, handleClose, returnToMainNav,}) => {
  const title='Supporting ULLR Maps'
  return (
<ModalPageTemplate DataInput={SupportULLRInfo} open={open} handleClose={handleClose} returnToMainNav={returnToMainNav} title={title} />
  )
}

const GetInTouchULLRPageModal = ({ open, handleClose, returnToMainNav,}) => {
  const title='Get In Touch'
  return (
<ModalPageTemplate DataInput={GetInTouchInfo} open={open} handleClose={handleClose} returnToMainNav={returnToMainNav} title={title} />
  )
}

export  {AboutULLRPageModal, SupportULLRPageModal, GetInTouchULLRPageModal};
